import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from "react-i18next"
import { Link, useHistory } from 'react-router-dom'
import Animate from 'components/animate/animate'
import Swal from 'sweetalert2'
import { Ecommerce_Login } from 'services/Ecommerce'
import { hasLineId } from 'utils/Ecommerce'
import ErrorTop from 'components/error/error-top'
import { hasFestariaId } from 'utils/Ecommerce'
import { ProfileDetailsContext } from "context/ProfileDetailsProvider"
import './style.scss'

const EcommerceLogin = () => {
  const { t } = useTranslation()
  const [profileDetails] = useContext(ProfileDetailsContext)
  const [data, setData] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const history = useHistory()

  const handleSubmit = async (e, data) => {
    e.preventDefault()
    setSubmitting(true)

    const values = {
      email: data.email,
      password: data.password
    }

    const token = localStorage.getItem("accesstoken") || "";
    const res = await Ecommerce_Login(values, token)
    // console.log('login res', res);
    // return

    if(res?.message === 'Success') {
      Swal.fire({
        icon: "success",
        title: t("common.login_success"),
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      setTimeout(() => {
        Swal.close();
        window.location.href = "/profile/edit";
      }, 3000);
    } else {
      console.log('error in ecommerceLogin:', res);
      setError(true)
      let errorDescription = res?.response?.data?.errors?.error_description
      // console.log(errorDescription)
      if(errorDescription.comment){
        errorDescription = res?.response?.data?.errors?.error_description?.comment
      }
      console.log(errorDescription)
      if(errorDescription === `User' credentials do not match our records`) {
        setErrorMessage(t("common.incorrect_credentials"))
      } else if(!errorDescription) {
        setErrorMessage(t("common.error"))
      } else {
        setErrorMessage(errorDescription)
      }
      setSubmitting(false)
    }
  }

  useEffect(() => {
    // Reset error message after 7 seconds
    let errorMessage = setTimeout(() => {
      setError(false)
      setErrorMessage('')
    }, 7000);

    return () => clearTimeout(errorMessage);
  }, [error, errorMessage])

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setData(prevState => ({ ...prevState, [name]: value }))
  }

  // useEffect(() => {
  //   if(hasFestariaId(profileDetails?.identities)) {
  //     console.log('redirect');
  //     history.push('/')
  //   }
  // }, [profileDetails, history])

  return (
    <Animate>
      <div className="login-page">
        {error && <ErrorTop message={errorMessage} />}
        <div className="main-text">
          <h1>フェスタリア公式オンラインショップに <br/> ご登録のある方はログインして <br/>情報の更新をお願いいたします。</h1>
        </div>
        <Form
          formInfo={data}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          submitting={submitting}
        />
                <div className="main-text" style={{paddingTop: '4rem'}}>
          <h1>新規にメンバー登録をされる方はこちら</h1>
          <div className="link-area">
            <Link to="/profile/create">
              <button type="button" className="create-ecommerce-button">
                新規メンバー登録
              </button>
            </Link>
          </div>
        </div>
        {hasLineId(profileDetails?.identities) &&
          <div className="main-text" style={{paddingTop: '4rem'}}>
              <>
                <h1>オンラインショップを使用されない方はこちら</h1>
                <div className="link-area">
                  <Link to="/home">
                    <button type="button" className="mypage-button">
                      仮会員証
                    </button>
                  </Link>
                </div>
              </>
          </div>
        }

      </div>
    </Animate>
  )
}

const Form = ({formInfo, handleSubmit, handleChange, submitting}) => {
  return (
    <form className="form" onSubmit={(e) => handleSubmit(e, formInfo)}>
      <div className="login">
        <h1>ログイン</h1>
        <p className="required">・必須</p>
      </div>
      <p style={{fontSize: '12px', textAlign: 'left', margin: '0'}}>ご登録がお済みの方は、メールアドレスとパスワードを入力してください</p>
      <fieldset disabled={submitting}>
        <label htmlFor="email">
          <div className="label-title">
            <span className="required">・</span>
            <span style={{color: 'black', fontWeight: 'bold'}}>メールアドレス</span>
          </div>
          <input
            name="email"
            type="email"
            value={formInfo.email || ""}
            onChange={(e) => handleChange(e)}
          />
        </label>
        <label htmlFor="password">
          <div className="label-title">
            <span className="required">・</span>
            <span style={{color: 'black', fontWeight: 'bold'}}>パスワード</span>
          </div>
          <input
            name="password"
            type="password"
            value={formInfo.password || ""}
            onChange={(e) => handleChange(e)}
          />
          <p className="text-req">半角英数字６文字以上</p> 
        </label>
      </fieldset>
      <div className="submit-area">
        <button className="send-btn" disabled={submitting || !formInfo.email || !formInfo.password } type="submit">ログイン</button>
      </div>
      <div className="link-area">
        <a href="https://onlineshop.festaria.jp/reminder/start">ECのパスワード変更・パスワードをお忘れの方はこちら &gt;&gt;</a>
      </div>
    </form>
  )
}

export default EcommerceLogin