import { useAuth0 } from "@auth0/auth0-react";
import React,{useEffect} from "react";
// import "./all.min.css";
import "./style.scss";
import Animate from "components/animate/animate";


const Login = () => {
  const { loginWithRedirect,isAuthenticated } = useAuth0();
  console.log(process.env.NODE_ENV)
  // const loginUrl = process.env.FESTARIA_URL || "https://onlineshop.festaria.jp/login";
  const loginUrl = (window.location.host.indexOf('festaria.dev.upbond.io') >= 0 || window.location.host.indexOf('localhost:3000') >= 0) ? 'https://fstrastg-ef.its.f-ace.jp/login' : 'https://onlineshop.festaria.jp/login';
  // const loginUrl = process.env.NODE_ENV == 'development' ? 'https://fstrastg-ef.its.f-ace.jp/login' :  "https://onlineshop.festaria.jp/login";
  useEffect(() => {
    console.log(isAuthenticated) 
    // 首先创建一个URL对象
    const url = new URL(window.location.href);
    // 使用URLSearchParams获取查询参数
    const params = new URLSearchParams(url.search);
    // 获取特定的查询参数
    const param = params.get('maintenance'); // 返回 'value1'
    if(param){
      localStorage.setItem('maintenance','1');
    }

    // if(!param && !localStorage.getItem('maintenance')){
    //   console.log('go to maintenance')
    //   window.location.href = "/maintenance";
    //   return
    // }

    if(isAuthenticated){
      window.location.href = "/home";
    }
  },[])

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/home",
      }
    });
  };

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/home",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };
  
  return (
    // <Animate>
      <div className="container_w">
        <div className="container__inner">
        <div className="container__frame ">
          <div className="login-card ">
          <div  className="switch-content is-active">
            <div className="festaria-logo">
                <img src="/festaria_logo.jpeg"  style={{ maxHeight:'100px',maxWidth:'150px' }}/>
            </div>
              <div className="mb-20">
                <input name="upstream" type="hidden" val="line" />
                <button type="submit" className="btn-social primary" onClick={() => handleSignUp()}>
                  <img src="/line.png" alt="line-login-logo"  style={{ height:'30px',width:'30px' }}/>
                  <span className="btn-social__text"> 新規登録</span>
                </button>
              </div>

						<div className="mb-10">
              <input name="upstream" type="hidden" val="line" />
							<button type="submit" className="btn-social secondary" onClick={() => handleLogin()}>
              <img src="/line_icon.png" alt="line-login-logo"  style={{ height:'30px',width:'30px' }}/>
								<span className="btn-social__text"> ログイン</span>
							</button>
						</div>
            {/* <div className="btn-social__text_outside">
              <p>LINEでログインできない方</p>
            </div>
            <div className="fes-link-primary">
              <div className="link-primary-button">
                <button type="button" data-link-id="singin-ec" className="switch-content-button">
                  <div className="text-left font-small">festaria Members Clubにご登録の</div>
                  <div className="underline">メールアドレス/パスワードでログイン</div>
                </button>
              </div>
            </div> */}
            <div className="fes-link-primary">
						<p className="link-primary-left-caption">festaria Members Clubを希望されない方</p>
						<br />
						<a className="link-primary-caption" href={loginUrl}>
							<span>旧アカウントのログイン画面へ</span>
						</a>
						<p className="link-primary-caption">festaria Members Clubのご利用にはLINE登録が必要です。
            <br />フェスタリアオンラインショップのアカウント情報が
            <br />引き継がれますので「新規メンバー登録」から
            <br />ご登録をお願いいたします
              </p>
              <div className="about-festaria-members">
                <p className="link-primary-left-caption">festaria Members Clubとは</p>
                <br/>
                <p className="link-primary-caption">お客様の会員情報・購入履歴・ポイント情報を一元管理し、フェスタリアオンラインショップと全国のフェスタリア系列店舗でポイントをご利用いただける仕組みです</p>
              </div>
            </div>
          </div>
          </div>
          </div>
        </div>
        </div>
    // </Animate>
  )
    
};

export default Login;

