import React, { useState, createContext, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Profile_Info } from "services/Profile";

export const ProfileDetailsContext = createContext();

const ProfileDetailsProvider = ({ children }) => {
  const { isAuthenticated,getAccessTokenSilently,logout } = useAuth0();
  const [profileDetails, setProfileDetails] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    const getProfileInfo = async () => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          localStorage.setItem("accesstoken", token);
          const prof = await Profile_Info(token, { signal: controller.signal})
          const profileData = prof.data;

          setProfileDetails(profileData);
          localStorage.setItem("userinfo", JSON.stringify(profileData));
        }
      } catch (error) {
        if (error.status === 403) {
          console.log('403 error in user profile fetch:', error);
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          })
        } else {
          console.log("error in user profile fetch:", error);
          if(error?.error_description == 'grant request is invalid'){
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            })
          }
        }
      }
    }
    getProfileInfo();
    return () => controller.abort();
  }, [getAccessTokenSilently])

  return (
    <ProfileDetailsContext.Provider value={[profileDetails]}>
      { children }
    </ProfileDetailsContext.Provider>
  )
};

export default ProfileDetailsProvider;